<template>
  <v-footer pa-0 ma-0 color="amber darken-2" padless>
    <v-row justify="center" no-gutters>
      <v-btn
        color="white"
        text
        rounded
        class="my-2"
        v-for="route in routes"
        :key="route.path"
        :to="route.path"
      >
        {{ route.name }}
      </v-btn>
      <v-col class="amber py-4 text-center black--text" cols="12">
        {{ new Date().getFullYear() }} — <strong>Kisbenedek Lilla</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>


<script>
export default {
  name: "Header",
  data: () => ({
    routes: [
      {
        path: "/",
        name: "Kezdőlap",
      },
      {
        path: "/faipar",
        name: "Faipar",
      },
      {
        path: "/foldmunka",
        name: "Földmunka",
      },
      {
        path: "/contact",
        name: "Elérhetőség",
      },
    ],
  }),
};
</script>
