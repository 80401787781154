<template>
  <div class="block latestPostBlock">
    <v-container>
      <v-row class="pa-ma-0" align="center" justify="center">
        <!--Földmunka-->
        <v-flex md5 sm12 class="pa-12">
          <v-card
            class="mr-auto my-12"
            height="400px"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            <v-card class="pa-0">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-row class="pa-ma-0">
                    <v-col cols="12" class="pa-ma-0">
                      <v-img
                        class="mt-n2 mb-n3 mx-1"
                        src="../assets/images/Tree1_resized.jpg"
                      ></v-img>

                      <v-fade-transition>
                        <v-overlay v-if="hover" absolute color="#fabd02">
                          <v-btn
                            color="#black"
                            position="fixed"
                            class="my-1"
                            x-large
                            to="/faipar"
                            ><h6>Bővebben</h6>
                          </v-btn>
                        </v-overlay>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </template>
              </v-hover>
            </v-card>

            <v-card>
              <v-row>
                <v-col cols="12">
                  <v-row class="mt-5 mb-n3" align="center" justify="center">
                    <v-img
                      class="mt-n2 mb-n3 ml-3"
                      max-width="80px"
                      src="../assets/images/treecut_icon.png"
                    ></v-img>
                  </v-row>
                  <h3
                    class="text-center my-4"
                    data-aos="fade"
                    data-aos-duration="100"
                  >
                    Faipar
                  </h3>
                  <v-card-text class="text--primary">
                    <div class="text-center">
                      <h5 data-aos="flip-up" data-aos-delay="30">
                        Ipari faanyag lábon vásárlás, termelés, szállítás, értékesítés!
                      </h5>
                    </div>
                    <div class="text-right">
                      <v-btn
                        plain
                        text
                        tile
                        position="fixed"
                        class="my-1"
                        x-large
                        to="/faipar"
                      >
                        <h6>Bővebben</h6>
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
        </v-flex>

        <!--Faipar-->
        <v-flex md5 sm12 class="pa-12">
          <v-card
            class="mr-auto my-12"
            height="400px"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            <v-card class="pa-0">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-row class="pa-ma-0">
                    <v-col cols="12" class="pa-ma-0">
                      <v-img
                        class="mt-n2 mb-n3 mx-1"
                        src="../assets/images/JCB1_resized.jpg"
                      ></v-img>

                      <v-fade-transition>
                        <v-overlay v-if="hover" absolute color="#fabd02">
                          <v-btn
                            color="#black"
                            position="fixed"
                            class="my-1"
                            x-large
                            to="/foldmunka"
                            ><h6>Bővebben</h6>
                          </v-btn>
                        </v-overlay>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </template>
              </v-hover>
            </v-card>

            <v-card>
              <v-row>
                <v-col cols="12">
                  <v-row class="mt-n1 mb-n1" align="center" justify="center">
                    <v-img
                      class="mt-n3 mb-n3 mx-1"
                      max-width="85px"
                      src="../assets/images/jcb_icon.png"
                    ></v-img>
                  </v-row>

                  <h3 class="text-center my-4" data-aos="fade">Földmunka</h3>
                  <v-card-text class="text--primary">
                    <div class="text-center">
                      <h5 data-aos="flip-up" data-aos-delay="30">
                        Gépi földmunka magánszemélyeknek és beruházások esetén
                        is.
                      </h5>
                    </div>
                    <div class="text-right">
                      <v-btn
                        plain
                        text
                        tile
                        position="fixed"
                        class="my-1"
                        x-large
                        to="/foldmunka"
                      >
                        <h6>Bővebben</h6>
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
        </v-flex>
      </v-row>
    </v-container>
  </div>
</template>





<script>
export default {
  name: "Referenciák",
  data: () => ({
    dialog: false,
    show: true,
  }),
};
</script>