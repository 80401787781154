<template>
  <v-content>
    <div class="contactBlock">
      <v-container>
        <div class="text-center">
          <h3>Kapcsolat</h3>

          <v-row align="center" justify="center">
            
              <v-flex md4 sm12 xs12>
              <v-flex sm12 class="my-5" align="center" justify="center">
                <v-avatar color="#fabd02">
                  <MdiSvg :size="48" light>
                    {{ mdiAt }}
                  </MdiSvg>
                </v-avatar>
              </v-flex>
              <v-flex sm12 align="center" justify="center">
                <h6>kisbenedek.attila@gmail.com</h6>
              </v-flex>
              <v-flex sm12 align="center" justify="center">
                <h6>kisbenedektamas567@gmail.com</h6>
              </v-flex>
              </v-flex>
              <v-flex md4 sm12 xs12>
            
              <v-flex sm12 class="my-5" align="center" justify="center">
                <v-avatar color="#fabd02">
                  <MdiSvg :size="48">
                    {{ mdiCellphoneBasic }}
                  </MdiSvg></v-avatar
                >
              </v-flex>
              <v-flex sm12 align="center" justify="center">
                <h6>+36 30 526 2020</h6>
              </v-flex>
                            <v-flex sm12 align="center" justify="center">
                <h6>+36 30 292 8201</h6>
              </v-flex>
              </v-flex>

              <v-flex md4 sm12 xs12 >
            
              <v-flex sm12 class="my-5" align="center" justify="center">
                <v-avatar color="#fabd02">
                  <MdiSvg :size="48">
                    {{ mdiMapMarker }}
                  </MdiSvg></v-avatar
                >
              </v-flex>
              <v-flex sm12 align="center" justify="center">
                <h6>3348 Szilvásvárad</h6>
              </v-flex>
              <v-flex sm12 align="center" justify="center">
                <h6>Széchenyi u. 28.</h6>
              </v-flex>
              </v-flex>


          </v-row>
        </div>
      </v-container>

      <v-container>
        <v-row>
          <v-col> </v-col>
        </v-row>

      </v-container>
      <div class="googlemap">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2663.7632561991845!2d20.390541716035333!3d48.114802479221744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4740813bce1feeb9%3A0xde59aa6011c3388!2sFaf%C3%B6ld%20Kft.!5e0!3m2!1shu!2shu!4v1633613061670!5m2!1shu!2shu"
          width="100%"
          height="450"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  </v-content>
</template>

<script>
import { mdiAt } from "@mdi/js";
import { mdiCellphoneBasic } from "@mdi/js";
import { mdiMapMarker } from "@mdi/js";
export default {
  name: "Contact",
  data: () => ({
    mdiAt,
    mdiCellphoneBasic,
    mdiMapMarker,
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    message: "",
    messageRules: [
      (v) => !!v || "Message is required",
      (v) => (v && v.length >= 10) || "Message must be more than 10 characters",
    ],
  }),

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true;
      }
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>
