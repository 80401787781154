<template>
  <div class="block galleryBlock">
    <v-container>
      <h2 class="text-center">Galéria</h2>
      <v-row>
        <v-tabs
          height="70px"
          color="#FABD02"
          v-model="tab"
          background-color="transparent"
          grow
        >
          <v-tab><h5>FÖLDMUNKA</h5></v-tab>
          <v-tab><h5>FAIPAR</h5></v-tab>

          <v-tab-item>
            <div class="block gallery">
              <v-container fluid>
                <v-row>
                  <v-col
                    v-for="item in items1"
                    :key="item.id"
                    class="d-flex child-flex"
                    cols="6"
                    sm="3"
                  >
                    <v-card flat tile class="d-flex">
                      <v-img
                        :src="item.src"
                        aspect-ratio="1"
                        class="grey lighen-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-tab-item>

          <v-tab-item>
            <div class="block gallery">
              <v-container fluid>
                <v-row color="grey">
                  <v-col
                    v-for="item in items2"
                    :key="item.id"
                    class="d-flex child-flex"
                    cols="6"
                    sm="3"
                  >
                    <v-card flat tile class="d-flex">
                      <v-img
                        :src="item.src"
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Gallery",
  data() {
    return {
      items1: [
        {
          id: 1,
          src: require("../assets/images/reference1_4_bontas_resized.jpg"),
        },
        {
          id: 2,
          src: require("../assets/images/reference1_2_bontas_resized.jpg"),
        },

        {
          id: 3,
          src: require("../assets/images/reference1_5_bontas_resized.jpeg"),
        },
                {
          id: 4,
          src: require("../assets/images/reference1_6_main_resized.jpg"),
        },

        {
          id: 5,
          src: require("../assets/images/reference2_7_atjaro_resized.jpg"),
        },

        {
          id: 6,
          src: require("../assets/images/reference2_8_bontas_resized.jpeg"),
        },

        {
          id: 7,
          src: require("../assets/images/reference2_10_betontörés_resized.jpeg"),
        },
        {
          id: 8,
          src: require("../assets/images/reference2_11_betontörés_resized.jpeg"),
        },

        {
          id: 9,
          src: require("../assets/images/reference3_1_furas_resized.jpg"),
        },

        {
          id: 10,
          src: require("../assets/images/reference3_3_furas_resized.jpg"),
        },

        {
          id: 11,
          src: require("../assets/images/reference3_8_tukor_resized.jpeg"),
        },

        {
          id: 12,
          src: require("../assets/images/reference3_10_tragya_resized.jpeg"),
        },

        {
          id: 13,
          src: require("../assets/images/reference4_1_fastruc_resized.jpeg"),
        },

        {
          id: 14,
          src: require("../assets/images/reference4_2_fastruc_resized.jpeg"),
        },

        {
          id: 15,
          src: require("../assets/images/Reference_resized.jpg"),
        },
      ],

      items2: [
        {
          id: 2,
          src: require("../assets/images/FA1_2.jpg"),
        },
        {
          id: 3,
          src: require("../assets/images/FA1_3.jpg"),
        },
        {
          id: 4,
          src: require("../assets/images/FA1_4.jpg"),
        },
        {
          id: 5,
          src: require("../assets/images/FA1_5.jpg"),
        },
        {
          id: 6,
          src: require("../assets/images/FA1_6.jpg"),
        },
        {
          id: 8,
          src: require("../assets/images/FA1_8.jpg"),
        },
        {
          id: 10,
          src: require("../assets/images/FA1_10.jpg"),
        },
        {
          id: 11,
          src: require("../assets/images/FA1_11.jpg"),
        },
      ],
    };
  },
};
</script>

<style>
#custom-tab-items {
  background-color: #fabd02 !important;
}
</style>