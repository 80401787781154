

<template>
  <div class="appbarBlock">
    <v-app-bar
      style="position: fixed"
      height="175px"
      color="rgba(46, 45, 45)"
      absolute
      prominent
      elevation="20"
    >
      <v-container>
        <v-layout wrap align-center>
          <v-flex xs6 sm3 md8 fill-height>
            <v-btn height="110px" text color="#FABD02">
              <router-link to="/">
                <v-img src="@/assets/logo3.png" width="150"></v-img>
              </router-link>
            </v-btn>
          </v-flex>

          <v-flex xs6 sm4 md2 fill-height pt-5>
            <v-row justify="center">
              <v-icon color="#FABD02" large>fas fa-phone</v-icon>
            </v-row>
            <v-row justify="center">
              <h5>+36 30 526 20 20</h5>
            </v-row>
          </v-flex>
        

          <v-flex hidden-xs-only sm4 md2 fill-height pt-5>
            <v-row justify="center">
              <v-icon color="#FABD02" large>fas fa-map-marker-alt</v-icon>
            </v-row>
            <v-row justify="center">
              <h5>Szilvásvárad</h5>
            </v-row>
          </v-flex>

          <template>
            <v-tabs
              fixed-tabs
              v-model="tabs"
              slider-color="#fabd02"
              background-color="rgba(46,45,45)"
              show-arrows
              grow
            >
              <v-flex md4 hidden-xs-only>
                <v-tab to="/">Főoldal</v-tab>
              </v-flex>
              <v-flex md4 hidden-xs-only>
                <v-menu :offset-y="offset" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-tab v-bind="attrs" v-on="on" to="/foldmunka"
                      >Szolgáltatások</v-tab
                    >
                  </template>

                  <v-list color="rgba(46,45,45)">
                    <v-list-item>
                      <v-btn outline block text to="/foldmunka">
                        ÉPÍTŐIPAR</v-btn
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-btn outline block text to="/faipar"> FAIPAR</v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-flex>
              <v-flex md4 hidden-xs-only>
                <v-tab to="/contact">Kapcsolat</v-tab>
              </v-flex>
              <v-flex md4 hidden-sm-and-up align-self-center>
                <v-row class="pa-0 ma-0">
                  <v-col cols="3">
                  
                    <v-app-bar-nav-icon
                      @click.stop="drawer = !drawer" color="#fabd02"
                    ></v-app-bar-nav-icon>
                  </v-col>

                  <v-col cols="9"> </v-col>
                </v-row>
              </v-flex>
            </v-tabs>
          </template>
        </v-layout>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      right
      temporary
      color="#fabd02"
      overlay-color="black"
      overlay-opacity="0.8"
    >
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item link to="/">
            <a>Főoldal</a>
          </v-list-item>

          <v-list-item link to="/foldmunka">
            <a>Földmunka</a>
          </v-list-item>
          <v-list-item link to="/faipar">
            <a>Faipar</a>
          </v-list-item>
          <v-list-item link to="/contact">
            <a>Elérhetőség</a>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    group: null,
    offset: true,
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },
  name: "Header",
  dialog: false,
};
</script>