<template>
  <v-content>
    <Hero />
    <Title />
    <LatestPost />
    <Gallery />


  </v-content>
</template>

<script>
import Hero from "../components/Hero";
import Title from "../components/Title.vue";
import LatestPost from "../components/LatestPost";
import Gallery from "../components/Gallery";

export default {
  name: "Home",

  components: {
    Hero,
    Title,
    LatestPost,
    Gallery,
  }
};
</script>