 
  <template>
  <v-content>
    <div class="TitleBlock_services">
              <v-col cols="11"></v-col>
        <v-col cols="11"></v-col>
        <v-col cols="11"></v-col>
        <v-col cols="11">
          <vue-typed-js :strings="['Faipar']" class="mx-4 mt-12">
            <div class="headline">
              <p><span class="typing"></span></p>
            </div>
          </vue-typed-js>
        </v-col>
    </div>

    <div class="Content_services">
      <div class="d-none d-md-block">
        <v-container>
          <v-card height="655px" class="ma-12 pr-4" elevation="0">
            <v-tabs vertical height="300px">
              <v-row>
                <v-col cols="12">
                  <v-spacer />
                  <v-toolbar elevation="10" color="rgba(46,45,45)" class="px-5">
                    <h2>Szolgáltatások</h2>
                  </v-toolbar>
                  <v-spacer />
                  <v-divider></v-divider>
                  <v-divider></v-divider>
                  <v-divider></v-divider>
                  <v-tab>
                    <v-icon left large class="ml-n7"> mdi-forest </v-icon>
                    Ipari faanyag
                  </v-tab>
                  <v-divider></v-divider>
                </v-col>
              </v-row>

              <v-tab-item v-for="item in items2" :key="item.id">
                <v-col cols="12" class="mx-4"
                  ><h2>
                    {{ item.heading }}
                  </h2>
                  {{ item.text }}
                </v-col>
                <v-col cols="12">
                  <v-img
                    class="ml-4"
                    :src="item.src"
                    :aspect-ratio="17 / 7"
                    max-width="950px"
                  >
                  </v-img>
                </v-col>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-container>
      </div>

      <div class="d-md-none">
        <v-card height="655px" class="mt-12" elevation="0">
          <v-tabs height="185px" centered grow>
            <v-row>
              <v-col cols="12">
                <v-toolbar
                  elevation="10"
                  color="rgba(46,45,45)"
                  class="pa-0 py-1"
                  ><v-spacer />
                  <v-toolbar-title>
                    <h2>Szolgáltatások</h2>
                  </v-toolbar-title>
                  <v-spacer />
                </v-toolbar>
                <v-tab>
                  <v-icon left large class="ml-n7">> mdi-forest </v-icon>
                  Ipari faanyag
                </v-tab>
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-tab-item v-for="item in items2" :key="item.id">
              <v-col cols="12"
                ><h2>
                  {{ item.heading }}
                </h2>
                {{ item.text }}
              </v-col>
              <v-col cols="12">
                <v-img :src="item.src" :aspect-ratio="17 / 7" max-width="950px">
                </v-img>
              </v-col>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </div>
    </div>
  </v-content>
</template>




<script>
export default {
  components: {},
  data: () => ({
    
    items: [
      {
        text: "",
        disabled: false,
        href: "/",
        color: "red",
        size: "24px",
      },
      {
        text: "",
        disabled: false,
        href: "szolgaltatasok",
        color: "grey",
        size: "24px",
      },
      {
        text: "",
        disabled: true,
        href: "breadcrumbs_link_2",
        color: "white",
        size: "24px",
      },
    ],
    items2: [
      {
        id: 1,
        src: require("../assets/images/Tree3.jpg"),
        heading: "Ipari faanyag",
        text: "Lábon vásárlás, ipari faanyag termelés, szállítás,  étrékesítés. ",
      },
    ],
  }),
};
</script>


