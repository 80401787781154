import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router'
import VueTypedJs from 'vue-typed-js'
import VueNumber from 'vue-number-animation'
import * as VueAos from 'vue-aos'
import AOS from "aos"
import "aos/dist/aos.css";
import MdiSvg from "@yeliulee/vue-mdi-svg/v2"






import Home from './views/Home';
import Contact from './views/Contact';
import Földmunka from './views/Földmunka';
import Faipar from './views/Faipar';
import NotFound from './views/NotFound';



Vue.use(VueRouter)
Vue.use(VueTypedJs)
Vue.use(VueNumber)
Vue.use(VueAos)
Vue.use(MdiSvg);

import './scss/main.scss';


Vue.config.productionTip = false

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: '/', component: Home },
    { path: '/contact', component: Contact },
    { path: '/faipar', component: Faipar },
    { path: '/foldmunka', component: Földmunka }, 
    { path: '*', component: NotFound }
  ],
  mode: 'history'
})

new Vue({
  router,
  vuetify,
  render: h => h(App),
  data:{

  },
  created(){
    AOS.init({
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100,

    });
  }
}).$mount('#app')



