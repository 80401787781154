<template>
  <div class="heroBlock">
    <v-flex md12>
        

    </v-flex>
  </div>
</template>


<script>
export default {
  name: "Hero",
  data() {
    return {
    };
  }
};
</script>