 
  <template>
  <v-content>
    <div class="TitleBlock_services2">
      <v-col cols="11"></v-col>
      <v-col cols="11"></v-col>
      <v-col cols="11"></v-col>
      <v-col cols="11">
        <vue-typed-js :strings="['Földmunka']" class="mx-4 mt-12">
          <div class="headline">
            <p><span class="typing"></span></p>
          </div>
        </vue-typed-js>
      </v-col>
    </div>

    <div class="Content_services">
      <v-container>
        <v-row justify="center">
          <!-- ########################################################### CAT ################################################################ -->
          <v-flex md4 sm12 class="pt-12 px-4">
            <v-card class="mx-auto" elevation="3">
              <v-img
                src="../assets/images/reference1_7_main_resized.jpg"
                height="200px"
              ></v-img>

              <v-card-title
                primary-title
                style="height: 100px; position: relative"
              >
                <div>
                  CAT M318 & Kobelco SK210
                  <v-card-actions>
                    <v-dialog v-model="dialog_money1" width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="#fabd02"
                          dark
                          absolute
                          top
                          right
                          fab
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-avatar small>
                            <MdiSvg :size="32" dark>
                              {{ mdiCurrencyUsd }}
                            </MdiSvg></v-avatar
                          >
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title>
                          <span class="text-h5"></span>
                        </v-card-title>
                        <v-card-text>
                          <a>12000 - 18000 Ft/óradíj</a></v-card-text
                        >
                        <v-divider width="540" class="ml-3"></v-divider>
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>Betontörés</v-list-item-title>
                            <v-list-item-subtitle
                              >Hammer 1 tonnás törőfejjel.</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider width="540" class="ml-3"></v-divider>

                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title
                              >Betonőrlés és újrahasznosítás</v-list-item-title
                            >
                            <v-list-item-subtitle
                              >MB Crusher betonőrlővel.</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider width="540" class="ml-3"></v-divider>

                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title
                              >Nagy mennyiségű földmunkák: akár napi 1000
                              m3!</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider width="540" class="ml-3"></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn icon x-large @click="dialog_money1 = false"
                            ><v-icon color="#fabd02" large>
                              mdi-close-box
                            </v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-card-actions>
                </div>
              </v-card-title>

              <v-card-subtitle>
                Forgórakodók. Fúrás, bontás, őrlés és építés!
              </v-card-subtitle>

              <v-card-actions>
                <v-dialog v-model="dialog" width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="orange lighten-2"
                      text
                      x-large
                      v-bind="attrs"
                      v-on="on"
                    >
                      Referenciák
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Referenciák</span>
                    </v-card-title>

                    <v-card-actions class="justify-center">
                      <v-flex md6 sm12>
                        <v-img
                          class="mx-auto"
                          aspect-ratio="1"
                          max-width="200px"
                          src="../assets/images/reference1_2_bontas_resized.jpg"
                        ></v-img>
                      </v-flex>
                      <v-divider vertical></v-divider>
                      <v-flex md6 sm12>
                        <v-img
                          class="mx-auto"
                          aspect-ratio="1"
                          max-width="200px"
                          src="../assets/images/reference1_3_bontas_resized.jpg"
                        ></v-img>
                      </v-flex>
                    </v-card-actions>

                    <v-divider width="490" class="mx-auto"></v-divider>

                    <v-card-actions>
                      <v-flex md6 sm12>
                        <v-img
                          class="mx-auto"
                          aspect-ratio="1"
                          max-width="200px"
                          src="../assets/images/reference1_4_bontas_resized.jpg"
                        ></v-img>
                      </v-flex>
                      <v-divider vertical></v-divider>
                      <v-flex md6 sm12>
                        <v-img
                          class="mx-auto"
                          aspect-ratio="1"
                          max-width="200px"
                          src="../assets/images/reference1_5_bontas_resized.jpeg"
                        ></v-img>
                      </v-flex>
                    </v-card-actions>

                    <v-card-text class="text-center">
                      Épületbontás
                    </v-card-text>
                    <v-card-actions class="justify-center">
                      <v-flex md12>
                        <v-img
                          class="mx-auto"
                          aspect-ratio="1"
                          max-width="400px"
                          src="../assets/images/FOLD1_5_1.jpg"
                        ></v-img>
                      </v-flex>
                    </v-card-actions>
                    <v-card-text class="text-center"> Őrlés </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn icon x-large @click="dialog = false"
                        ><v-icon color="#fabd02" large> mdi-close-box </v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-spacer></v-spacer>

                <v-btn icon @click="show2 = !show2">
                  <v-icon color="#fabd02" large>{{
                    show2 ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <v-card v-show="show2">
                  <v-divider></v-divider>
                  <v-list elevation="0">
                    <v-list-item>
                      Nagy mennyiségű földmunkák: 100-150 m3/óra. Bontott föld,
                      törmelék, osztályzott és osztályzatlan kő mozgatása.
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      Törőfejes munkák, 1 tonnás Hammer törőfejjel.
                      Épületbontás, újrahasznosítás.
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      Beton őrlés, újrahasznosítás. MB Crusher 70.2 mobil
                      darálóval, 8-15 t/h.
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      Függőleges talajfúrás 0,2-0,8 méter átmérőben 6 méter
                      mélységig. Épületbontás, újrahasznosítás, betonőrlés
                      (elszállítás igény szerint).
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      Műszeres talajszintezés. Tükörszedés 1.5 m3-es billenős
                      rézsűkanállal.
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      >Kanalak: 60-100-220-as + igény szerint.</v-list-item
                    >
                  </v-list>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-flex>

          <!-- ################################################# JCB ########################################################################-->
          <v-flex md4 sm12 class="pt-12 px-4">
            <v-card class="mx-auto" elevation="3">
              <v-img src="../assets/images/FOLD1_3.jpg" height="200px"></v-img>
              <v-card-title
                primary-title
                style="height: 100px; position: relative"
              >
                <div>
                  JCB 3CX illetve 4CX
                  <v-card-actions>
                    <v-dialog v-model="dialog_money2" width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="#fabd02"
                          dark
                          absolute
                          top
                          right
                          fab
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-avatar small>
                            <MdiSvg :size="32" dark>
                              {{ mdiCurrencyUsd }}
                            </MdiSvg></v-avatar
                          >
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title>
                          <span class="text-h5"></span>
                        </v-card-title>
                        <v-card-text>
                          <a>10000 - 15000 Ft/óradíj</a></v-card-text
                        >
                        <v-divider width="540" class="ml-3"></v-divider>
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title
                              >Ár adapter- és mennyiségfüggő</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider width="540" class="ml-3"></v-divider>

                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title
                              >Mindennemű földmunkák</v-list-item-title
                            >
                            <v-list-item-subtitle
                              >30-tól 180 cm-es földkanalakig, raklapvilla,
                              törőfej.</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider width="540" class="ml-3"></v-divider>

                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>Ásás</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider width="540" class="ml-3"></v-divider>

                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title
                              >Föld és betonmozgatás,
                              betontörés</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider width="540" class="ml-3"></v-divider>

                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title
                              >Törőfejes munkák</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider width="540" class="ml-3"></v-divider>

                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title
                              >Lézeres szintezés</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider width="540" class="ml-3"></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn icon x-large @click="dialog_money2 = false"
                            ><v-icon color="#fabd02" large>
                              mdi-close-box
                            </v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-card-actions>
                </div>
              </v-card-title>

              <v-card-subtitle> Univerzális kotró-rakodók. </v-card-subtitle>

              <v-card-actions>
                <v-btn color="orange lighten-2" text x-large>
                  Referenciák
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn icon @click="show3 = !show3">
                  <v-icon color="#fabd02" large>{{
                    show3 ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <v-card v-show="show3">
                  <v-list>
                    <v-list-item>
                      Minden nemű földmunkák (40-80 m3/h).</v-list-item
                    ><v-divider></v-divider>
                    <v-list-item>
                      Építési terület előkészítés, műszeres
                      terepszintezés.</v-list-item
                    ><v-divider></v-divider>
                    <v-list-item>
                      Alapozás, víz-, gáz-, szennyvízvezeték
                      létesítés.</v-list-item
                    ><v-divider></v-divider>
                    <v-list-item
                      >Talajcsere, termőföld visszatöltés, földszállítás.
                    </v-list-item>
                    <v-divider></v-divider
                    ><v-list-item>
                      Törőfejes munkák (400kg-os törőfejjel!)
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item> Raklapvillázás. </v-list-item
                    ><v-divider></v-divider>
                    <v-list-item>
                      Talapjtömörítettség biztosítása (lapvibrátor, döngölőbéka,
                      úthenger).</v-list-item
                    ><v-divider></v-divider
                    ><v-list-item>
                      Elhanyagolt, gazos területek rendezése (tuskózás,
                      zöldhulladék depózás, komposztálás).</v-list-item
                    >
                    <v-divider></v-divider
                    ><v-list-item>
                      Erdészeti, mezőgazdasági feltáró útkészítés (dőlésszög nem
                      akadály!).</v-list-item
                    >
                  </v-list>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-flex>

          <!-- ################################################################## BOBCAT ######################################################### -->
          <v-flex md4 sm12 class="pt-12 px-4">
            <v-card class="mx-auto" elevation="3">
              <v-img
                src="../assets/images/JPEG kép 17.jpeg"
                height="200px"
              ></v-img>

              <v-card-title
                primary-title
                style="height: 100px; position: relative"
              >
                <div>
                  BOBCAT S550
                  <v-card-actions>
                    <v-dialog v-model="dialog_money3" width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="#fabd02"
                          dark
                          absolute
                          top
                          right
                          fab
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-avatar small>
                            <MdiSvg :size="32" dark>
                              {{ mdiCurrencyUsd }}
                            </MdiSvg></v-avatar
                          >
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title>
                          <span class="text-h5"></span>
                        </v-card-title>
                        <v-card-text>
                          <a>9000 - 12000 Ft/óradíj</a></v-card-text
                        >
                        <v-divider width="540" class="ml-3"></v-divider>
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title
                              >Ár adapter- és mennyiségfüggő</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider width="540" class="ml-3"></v-divider>
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>Földmozgatás</v-list-item-title>
                            <v-list-item-subtitle
                              >0.4 m3-es nyitható kanál.</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider width="540" class="ml-3"></v-divider>

                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>Ásás</v-list-item-title>
                            <v-list-item-subtitle
                              >Mélyásószerelék: 20-30-40-50-60-120 cm-es
                              kanalakkal.</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider width="540" class="ml-3"></v-divider>

                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title
                              >Függőleges talajfúrás</v-list-item-title
                            >
                            <v-list-item-subtitle
                              >0,15 m átmérőtől 0,8 átmérőig 2,5 méter
                              mélyen.</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider width="540" class="ml-3"></v-divider>

                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title
                              >Trágyavilla, raklapvilla,
                              betontörőfej.</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider width="540" class="ml-3"></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn icon x-large @click="dialog_money3 = false"
                            ><v-icon color="#fabd02" large>
                              mdi-close-box
                            </v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-card-actions>
                </div>
              </v-card-title>

              <v-card-subtitle> Csúszókormányzású rakodógép. </v-card-subtitle>

              <v-card-actions>
                <v-btn color="orange lighten-2" text x-large>
                  Referenciák
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn icon @click="show1 = !show1">
                  <v-icon large color="#fabd02">{{
                    show1 ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <v-card v-show="show1">
                  <v-list elevation="0">
                    <v-list-item>
                      Precíziós, kisebb tömegű földmunkák.
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      Függőleges talajfúrás 0,15-0,8 m átmérőig, 2,5 méter
                      mélyen!
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      >Mélyásószerelék: 30-40-50-60-120 kanalakkal
                      földmunkák.</v-list-item
                    ><v-divider></v-divider>
                    <v-list-item>
                      Törőfejes munkák (betontörés). Raklapvillázás. </v-list-item
                    ><v-divider></v-divider
                    ><v-list-item
                      >Trágyavilla, istállók takarítása, depózása. </v-list-item
                    ><v-divider></v-divider
                    ><v-list-item
                      >Talajszintezés, tereprendezés nyitható kanállal.
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-flex>

          <!-- ###################################################### FASTRUC ###################################################-->
          <v-flex md4 sm12 class="pt-12 px-4">
            <v-card class="mx-auto" elevation="3">
              <v-img
                src="../assets/images/FOLD1_32.jpeg"
                height="200px"
              ></v-img>
              <v-card-title
                primary-title
                style="height: 100px; position: relative"
              >
                <div>
                  JCB Fastruc
                  <v-card-actions>
                    <v-dialog v-model="dialog_money4" width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="#fabd02"
                          dark
                          absolute
                          top
                          right
                          fab
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-avatar small>
                            <MdiSvg :size="32" dark>
                              {{ mdiCurrencyUsd }}
                            </MdiSvg></v-avatar
                          >
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title>
                          <span class="text-h5"></span>
                        </v-card-title>
                        <v-card-text>
                          <a>Ár munka felmérése után. </a></v-card-text
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn icon x-large @click="dialog_money4 = false"
                            ><v-icon color="#fabd02" large>
                              mdi-close-box
                            </v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-card-actions>
                </div>
              </v-card-title>

              <v-card-subtitle>
                Éptőipari, mezőgazdasági munkák.
              </v-card-subtitle>

              <v-card-actions>
                <v-btn color="orange lighten-2" text x-large>
                  Referenciák
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn icon @click="show4 = !show4">
                  <v-icon color="#fabd02" large>{{
                    show4 ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <v-card v-show="show4">
                  <v-list
                    ><v-list-item
                      >Anyagmozgatás (billenős fix pótkocsik, 32t vontatással).
                    </v-list-item></v-list
                  >
                  <v-divider></v-divider>
                  <v-list
                    ><v-list-item
                      >Talajlazítás, talajmarózás, terület zúzás, rekultiválás.
                    </v-list-item></v-list
                  >
                  <v-divider></v-divider>
                  <v-list
                    ><v-list-item
                      >Szántás, kaszálás, bálázás körbálázóval (Krone Vario Pack
                      1500).
                    </v-list-item></v-list
                  >
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-flex>

          <!--########################################################## PLATOS ########################################################### -->
          <v-flex md4 sm12 class="pt-12 px-4">
            <v-card class="mx-auto" elevation="3">
              <v-img
                src="../assets/images/Reference_resized.jpg"
                height="200px"
              ></v-img>

              <v-card-title
                primary-title
                style="height: 100px; position: relative"
              >
                <div>
                  Platós teherautók
                  <v-card-actions>
                    <v-dialog v-model="dialog_money5" width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="#fabd02"
                          dark
                          absolute
                          top
                          right
                          fab
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-avatar small>
                            <MdiSvg :size="32" dark>
                              {{ mdiCurrencyUsd }}
                            </MdiSvg></v-avatar
                          >
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title>
                          <span class="text-h5"></span>
                        </v-card-title>
                        <v-card-text> <a>1000 Ft/m3-től</a></v-card-text>
                        <v-divider width="540" class="ml-3"></v-divider>
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title
                              >Távolságtól és mennyiségtől függően.
                            </v-list-item-title>
                            <v-list-item-subtitle
                              >2-3-4 tengelyes tehergépkocsik (3-12-16
                              tonna).</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider width="540" class="ml-3"></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn icon x-large @click="dialog_money5 = false"
                            ><v-icon color="#fabd02" large>
                              mdi-close-box
                            </v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-card-actions>
                </div>
              </v-card-title>
              <v-card-subtitle>
                Billenős teherautók, utánfutók.
              </v-card-subtitle>

              <v-card-actions>
                <v-btn color="orange lighten-2" text x-large>
                  Referenciák
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn icon @click="show5 = !show5">
                  <v-icon color="#fabd02" large>{{
                    show5 ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <v-card v-show="show5">
                  <v-list
                    ><v-list-item
                      >2-3-4 tengelyes billenős teherautók (3-12-18 m3). Anyag-
                      és földmozgatása.
                    </v-list-item></v-list
                  >
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-flex>
        </v-row>
      </v-container>
    </div>
  </v-content>
</template>




<script>
import { mdiCurrencyUsd } from "@mdi/js";
export default {
  components: {},
  data: () => ({
    mdiCurrencyUsd,
    dialog: false,
    dialog_money1: false,
    dialog_money2: false,
    dialog_money3: false,
    dialog_money4: false,
    dialog_money5: false,
    show1: false,
    show2: false,
    show3: false,
    show4: false,
    show5: false,
    show16: false,
    items: [
      {
        text: "",
        disabled: false,
        href: "/",
        color: "red",
        size: "24px",
      },
      {
        text: "",
        disabled: false,
        href: "szolgaltatasok",
        color: "grey",
        size: "24px",
      },
      {
        text: "",
        disabled: true,
        href: "breadcrumbs_link_2",
        color: "white",
        size: "24px",
      },
    ],
  }),
};
</script>


